export const Settings = (server) => {
  server.get('/settings', () => {
    return {
      settings: {
        email: {
          sync: true,
          syncedValue: 'info@testhotel-post.com'
        },
        phone: {
          sync: true,
          syncedValue: '+390473538800'
        },
        name: {
          value: 'testhotel',
          sync: true,
          syncedValue: 'Testhotel Post'
        },
        address: {
          value: 'Street',
          sync: true,
          syncedValue: 'Street'
        }
      }
    };
  });

  server.get('/packages', () => {
    return { packages: [] };
  });

  server.get('/shippings', () => {
    return { shippings: [] };
  });

  server.post('/shippings', () => {
    return { shippings: [{ id: 1 }] }; // Add an id to the shipping object
  });

  server.put('/shippings/:id', () => {
    return { shippings: [{ id: 1 }] }; // Add an id to the shipping object
  });

  server.put('/settings', (schema, request) => {
    const requestBody = JSON.parse(request.requestBody);

    return {
      settings: Object.assign(
        {
          email: {
            sync: true,
            syncedValue: 'info@testhotel-post.com'
          },
          phone: {
            sync: true,
            syncedValue: '+390473538800'
          },
          name: 'Testhotel Post',
          address: 'Street'
        },
        requestBody
      )
    };
  });
};
