import ENV from 'additive-voucher/config/environment';
import { Response } from 'miragejs';

export const Countries = (server) => {
  server.get(`${ENV.APP.localizationApiBaseHost}/api/countries`, () => {
    return new Response(
      200,
      {},
      {
        countries: [
          { code: 'IT', name: 'Italien' },
          { code: 'DE', name: 'Deutschland' },
          { code: 'AT', name: '\u00d6sterreich' },
          { code: 'CH', name: 'Schweiz' }
        ]
      }
    );
  });
};
