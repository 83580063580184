import { starter as starterOrganization } from '../organization';
import { partnerMember as partnerMemberUser, admin as adminUser } from '../user';
import { shipping } from '../settings';

export const DEFAULT_ORG1_ID = 'test-org';

export default (server, options = {}) => {
  const _options = Object.assign(
    { loadOrganization: true, loadUser: true, loadAdminUser: false, loadShippingOption: false },
    options
  );
  server.create('organization', {
    id: DEFAULT_ORG1_ID,
    name: DEFAULT_ORG1_ID,
    isPartner: false
  });
  server.create('user');
  server.create('user', { role: 'manager' });
  server.create('user', { role: 'admin' });
  server.create('user', { isAdditive: false, isPartner: false });
  server.create('user', { isAdditive: false, isPartner: false, role: 'manager' });
  server.create('user', { isAdditive: false, isPartner: false, role: 'admin' });

  _options.loadOrganization && starterOrganization(server, DEFAULT_ORG1_ID);
  _options.loadUser && partnerMemberUser(server);
  _options.loadAdminUser && adminUser(server);
  _options.loadShippingOption && shipping(server);
};
