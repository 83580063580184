export const shipping = (server) => {
  server.get('/shippings', () => {
    return {
      shippings: [
        {
          id: '7fdb5e20-c21e-412f-a226-dba06a8e034c',
          name: 'Asdf',
          type: 'other',
          description: 'Test shipping',
          language: 'de',
          availableLanguages: ['de'],
          prices: [
            {
              price: 100,
              countries: ['IT']
            }
          ],
          enabled: false,
          default: false
        }
      ]
    };
  });
};
