import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { func, obj, bool } from 'prop-types';

/**
 * HOC to output the price<->countries pairs
 */
export default class AvShippingPricesComponent extends Component {
  @service uiLocale;
  @service localization;

  @arg(obj)
  @tracked
  editModel = null;

  @arg(obj)
  @tracked
  changeset = null;

  @arg(bool)
  @tracked
  isReadOnly = false;

  @arg(bool)
  @tracked
  isFormTouched = false;

  @arg(func)
  onPriceUpdate() {}
  @arg(func)
  onPriceDelete() {}

  @tracked
  _allCountries = [];

  constructor() {
    super(...arguments);
    const locale = this.uiLocale?.locale || 'de';
    this.localization.getCountries(locale).then((countries) => {
      this._allCountries = countries || [];
    });
  }
}
