import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { func, obj, bool, number } from 'prop-types';
import { arg } from 'ember-arg-types';
import { action } from '@ember/object';

/**
 * Component to handle one single shipping price<->countries pair.
 *
 * It greps its errors out of changeset and shows them.
 * To grep its related error out of the changeset errors array
 * we use the "arrayIndex" prop, which indicates the array position
 * which gets validated and will be inside the errors-array
 *
 */
export default class AvShippingPriceComponent extends Component {
  @service uiAppSettings;
  @service intl;
  @arg(number)
  @tracked
  arrayIndex = null;

  @arg(obj)
  @tracked
  changeset = null;

  @arg(obj)
  @tracked
  countries = null;

  @arg(obj)
  @tracked
  price = null;

  @arg(bool)
  @tracked
  isReadOnly = false;

  @tracked
  _errors = {};

  @arg(func)
  onPriceUpdate() {}

  @arg(func)
  onPriceDelete() {}

  @arg(bool)
  @tracked
  isFormTouched = false;

  get options() {
    if (!this.countries) {
      return [];
    }
    return [...this.countries];
  }

  get _numericInputErrorMessage() {
    const prices = this.changeset.get('prices');
    const { price } = prices[this.arrayIndex];
    if (isNaN(price) || price < 0) {
      return this.intl.t('errors.greaterZero');
    }
    return '';
  }

  get _countryErrorMessage() {
    if (!this.isFormTouched) {
      return '';
    }
    const prices = this.changeset.get('prices');
    const price = prices[this.arrayIndex];
    if (!price?.countries || price?.countries?.length === 0) {
      return this.intl.t('errors.required');
    }

    return '';
  }

  get _preSelected() {
    const prices = this.changeset.get('prices');
    const price = prices[this.arrayIndex];

    if (!this.options || !price?.countries) {
      return [];
    }

    const priceCountries = price.countries;
    const priceCount = priceCountries.length;
    let _preSelected = [];

    this.options.some((country) => {
      if (priceCountries.includes(country.code)) {
        _preSelected.push(country);
      }

      return _preSelected.length === priceCount;
    });

    return _preSelected;
  }

  @action
  onCountryChange(country, checked) {
    const prices = [...this.changeset.get('prices')];
    const price = prices[this.arrayIndex];

    let newCountries = price.countries || [];

    const index = newCountries.indexOf(country.code);
    if (checked) {
      if (index === -1) newCountries.push(country.code);
    } else {
      if (index >= 0) {
        newCountries = [...newCountries.slice(0, index), ...newCountries.slice(index + 1)];
      }
    }

    this.onPriceUpdate({ countries: newCountries, price: price.price });
  }

  @action
  onPriceChange(price) {
    this.onPriceUpdate({ countries: this.price.countries, price });
  }
}
